import React, { useEffect, useState } from 'react'
import lider1 from "../assets/lider1.jpeg"
import f1 from "../assets/1.jpeg"
import f2 from "../assets/3.jpeg"
import { AiFillCheckCircle } from "react-icons/ai";
import { BsCaretRightFill, BsCaretLeftFill } from "react-icons/bs";
import "../styles/App.css"
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";

export default function About() {
    const [about, setAbout] = useState([])
    const MyAbout = async () => {
        const querySnapshot = await getDocs(collection(db, "about"));
        let document = []
        querySnapshot.forEach((doc) => {
            document.push({ ...doc.data(), id: doc.id })
        });
        setAbout(document)
    }

    useEffect(() => {
        MyAbout()
    }, [])
    return (
        <div>
            <>
                <img src={f1} className="img-fluid" alt="logo" minWidth="100%" width="100%" height="400" />
            </>
            <div className='container mt-4'>
                <div className="row">
                    <div className="col-7"><img src={f2} alt="logo" minHeight="300" maxHeigth="600" minWidth="200" width="100%" height="600" /></div>

                    <div className="col-5">
                        <h2 className='text-center'>Neden Professional Team?</h2>
                        <br />
                        <h5>Biz sizin için varız...</h5>
                        <br />
                        {about.map((item) => (
                            <p key={item.id}><AiFillCheckCircle color={"#68B984"} size={26} /> {item.item}</p>
                        ))}

                    </div>
                </div>
                <div className='row'>
                    <div className="col-12 pt-5 mt-5 mb-1">
                        <h3 className="text-center" >
                            <BsCaretRightFill color="#306" /> Liderlerimiz <BsCaretLeftFill color="#306" /></h3>
                    </div>
                    <div className="col-12" id='lider'>
                        <div className="card mb-3">
                            <div className="row no-gutters">
                                <div className="col-md-4">
                                    <img src={lider1} className="card-img" alt="..." />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h2 className="card-title">Mehmet Hacım</h2>
                                        <h6 className="card-title">Biyografi</h6>
                                        <p>
                                            1985 uşak doğumluyum, evli ve bir çocuk babasıyım. 12 yıl bir fiil telefon satış ve tamiri ile ilgilendim yani klasik bir esnafken karşıma liderim Pearl Master Ferdi Özdemir çıktı ve beni One More International ile tanışmama vesile oldu. (çünkü bana bu sözü söylemişti başkalarının yanında çalışarak ancak başkalarını zengin edersin kendin için çalışırsan kendini zengin edersin… ) demişti bu söz üzerine 12 yıllık telefonculuk sektörümü bırakarak One More International işini full time yapma kararı aldım. Bu  benim ilk network projem, ilk deneyimim oldu. 24 aydır tek işim tek gelirim olarak One More International ile yoluma devam etmekteyim. Bu 24 aylık süreçte maddi özgürlüğüme  ve bireysel özgürlüğüme hak kazandım ve aynı anda hayalini bile kurmaktan korktuğum cla 200 mercedes bir aracı hayatımda gördüm.(hayallerin neyse hayatın odur) Bu yolculukta beni yalnız bırakmayan başta sevgili eşim Apprentice liderim Sibel Hacım’a motivasyon kaynağım biricik oğlum Mehmet Emin Hacım’a, sponsorum Pearl Master Ferdi Özdemir ve bana ışık olanan bütün üst sponsorlarıma ve Dolphins Team aileme ve beni bu yolda yalnız bırakmayan bütün takım arkadaşlarıma sonsuz teşekkür ederim.
                                        </p>
                                        <p> Her zaman şunu söylemişimdir …
                                        </p>
                                        <p>  Dünü silemessin fakat yarını yazabilirsin… Bu kendi elimizde!
                                        </p>
                                        <p>   One More hayatları değiştiren mükemmel ötesi bir proje hedef ve hayalleri olan gerçekten onlara  sahip çıkan insanların işidir!
                                        </p>
                                        <p>  Pearl Master
                                        </p>
                                        <p>  <b> Mehmet Hacim</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}
