import styled from 'styled-components'
import { NavLink as Link } from "react-router-dom";

export const PrimaryNav = styled.nav`
  z-index: 14;
  height: ${(props) => (props.extendNavbar ? "100vh" : "90px")};
  width:100%;
  display: flex;
  background: #fff;
  flex-direction: row;
  justify-content: space-between;


  @media (min-width: 1200px) {
    height: 90px;
  }
`;

export const NavLogo = styled.img`
  width: 120px;
  height:90px;
  justify-content: start;
  display:inline-block;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`

export const MenuLink = styled(Link)`
  color: #003;
  height:70px;
  width:120px;
  margin-right:10px;
  font-size:1.0em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration:none;
  }
  &:hover {
    background-color:#003;
    color:white;
  }
  &:active {
    color: red;
  }
  &.${props => props.activeClassName} {
    color: yellow;
  }
`

export const MenuRight = styled(Link)`
  color: #003;
  height:70px;
  width:100px;
  font-size:1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration:none;
  }
  @media screen and (min-width: 1200px) {
    display: none;
  }
  &:hover {
    color:#fff;
    background-color:#005;
  }
  &:active {
    color: red;
  }`;

export const NavbarExtendedContainer = styled.div`
  display: flex;
  height:100%;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1200px) {
    display: flex;
  }
`;

export const NavbarLinkExtended = styled(Link)`
  color: #003;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
  
  &:hover {
    color:#fff;
    background-color:#005;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`;
