import React, { useState } from 'react';
import { IletisimBox, IletisimLink } from './Iletisim.style';
import { Formik } from 'formik';
import { collection, addDoc, } from "firebase/firestore";
import { db } from "../../firebase/firebase";

import Loading from "../../pages/Loading"
import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
    adsoyad: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    konu: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    tel: Yup.string()
        .min(2, 'Too Short!')
        .max(14, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});

export default function Iletisim() {
    const [loading, setLoading] = useState(false)
    
    async function writeUserData(adsoyad, konu, email, tel, icerik) {
        setLoading(true)
        await addDoc(collection(db, "mesajlar/"), {
            adsoyad: adsoyad,
            konu: konu,
            email: email,
            tel: tel,
            icerik: icerik
        })
            .then(() => { setLoading(false) })
            .catch(() => { setLoading(false) })
    }
    return (
        <IletisimBox style={{
            boxShadow: '0px 0px 13px 2px #aaa',
            marginTop: '-3em',
            paddingLeft: '1em',
            position: "sticky",
            paddingTop: '3em',
            marginBottom: "2em"
        }}>
            {
                loading == true ?
                    <Loading />
                    :
                    <Formik
                        initialValues={{ adsoyad: "", konu: "", email: "", tel: "", icerik: "" }}
                        onSubmit={(values) => {
                            writeUserData(values.adsoyad, values.konu, values.email, values.tel, values.icerik)
                        }}
                        validationSchema={SignupSchema}
                    >
                        {({ handleChange, handleSubmit, errors }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group row align-items-center">
                                    <h3>İletişim</h3>
                                    <small>Formu doldurarak bizimle iletişime geçebilirsiniz...</small>
                                    <br />
                                    <div className='row'>
                                        <div className="col-sm-6 pt-4">
                                            <input type="text" onChange={handleChange} name="adsoyad" className="form-control form-control-lg"
                                                placeholder="AD SOYAD" />
                                            {errors.adsoyad ? (
                                                <div style={{ color: "red" }}>{"AD SOYAD Giriniz"}</div>
                                            ) : null}
                                            <br />
                                            <input type="text" onChange={handleChange} name="konu" className="form-control form-control-lg"
                                                placeholder="KONU" />
                                            {errors.konu ? (
                                                <div style={{ color: "red" }}>{"KONU Giriniz"}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pt-4">
                                            <input type="email" onChange={handleChange} name="email" className="form-control form-control-lg"
                                                placeholder="E-MAİL" />
                                            {errors.konu ? (
                                                <div style={{ color: "red" }}>{"Email Giriniz"}</div>
                                            ) : null}
                                            <br />
                                            <input type="tel" onChange={handleChange} name="tel" className="form-control form-control-lg"
                                                placeholder="TELEFON" />
                                            {errors.tel ? (
                                                <div style={{ color: "red" }}>{"Tel Giriniz"}</div>
                                            ) : null}
                                        </div>
                                        <br />

                                    </div>
                                </div>
                                <br />
                                <div>
                                    <div className="col-sm-11">
                                        <textarea className="form-control form-control-lg" placeholder='EKLEMEK İSTEDİKLERİNİZ' name="icerik" rows="3"></textarea>
                                        <br />
                                        <IletisimLink className='align-items-center' type="submit">Gönder</IletisimLink>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
            }
        </IletisimBox>
    )
}
