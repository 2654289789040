import React from 'react'
import { Container, MenuLink } from "./LiderCard.style"
import { Fade, Slide, Bounce } from "react-awesome-reveal";
// import { Col, Row } from 'react-bootstrap';

export default function LiderCard() {
    return (
        <Container>
            <Fade>
                <div className='container pl-3'>
                    <div className='row'>
                        <div className='col'>
                            <h1>Liderlerimiz</h1>
                            <Slide><h5>Birbirinden Tecrübeli Liderlerimizle Hemen Tanışıp Bilgi Alabilirsiniz...</h5></Slide>
                            <br />
                            <MenuLink to="about#lider">İncele</MenuLink>
                        </div>
                    </div>
                </div>
            </Fade >
        </Container>
    )
}
