import React from 'react'
import { useLocation } from 'react-router-dom'
import YouTube from 'react-youtube';

export default function ProductDetail(props) {
    const location = useLocation()
    const urunAd = location.state[0]
    const urunResim = location.state[1]
    const urunDetayBaslik = location.state[2]
    const urunDetay1 = location.state[3]
    const urunDetay2 = location.state[4]
    const urunDetay3 = location.state[5]
    const urunVideo = location.state[6]

    const opts = {
        height: "600px",
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };
    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.startVideo();
    }
    return (
        <div className='container' id='title'>
            <h1 className='text-center'>{urunAd}</h1>
            <div className="row p-4">
                <div className="col-5">
                    <img src={urunResim} alt="logo" className="float-left"
                        minHeight="600" minWidth="400" width="100%" height="600" />
                </div>
                <div className="col-7">
                    <p>{urunDetay1}</p>
                    <h4 className="pt-4 pb-3">{urunDetayBaslik}</h4>
                    <p>{urunDetay2}</p>
                    <br />
                    <p>{urunDetay3}</p>
                </div>
            </div>
            {
                urunVideo != "" &&
                <div className="row p-4 pb-5">
                    <b><p>Detaylı bilgi için videomuzu izleyebilirsiniz...</p></b>
                    <br />
                    <YouTube videoId={urunVideo} opts={opts} onReady={_onReady} />
                </div>
            }
        </div>
    )
}
