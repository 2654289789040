import React from 'react'
import { Container, MenuLink } from "./Bilet.style"
import { Fade, Slide, Bounce } from "react-awesome-reveal";
//import { Col, Row } from 'react-bootstrap';
import bilet1 from "../../assets/bilet1.jpeg"
import bilet2 from "../../assets/bilet2.jpeg"
export default function Bilet() {
    return (
        <div className='container'>
            <Container>
                <Fade>
                    {/* <div className='container pl-3'> */}
                    <div className='row'>
                        {/* <div className='col'>
                            <Slide><h1 style={{ color: "#fff" }}>Misafirimiz Olun...</h1></Slide>
                            <Slide><h4 style={{ color: "#eee" }}>Gerçekleştireceğimiz etkinliklerde bize katılmak için hemen yerinizi ayırtın...</h4></Slide>
                            <br />
                            <MenuLink target={'_blank'} rel="noreferrer" href="https://chakramice.com/etkinlik/10-12-mart-kariyer-atolyesi-1/">Bilet Al</MenuLink>
                        </div> */}
                        <div id="carouselExampleInterval" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active" data-interval="10000">
                                    <img src={bilet1} className="d-block w-100" height={500} alt="..." />
                                </div>
                                <div class="carousel-item pl-5" data-interval="2000">
                                    <div className='pl-5 align-center'>
                                        <img src={bilet2} class="d-block w-100" height={500} alt="..." />
                                    </div>
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleInterval" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleInterval" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </Fade >
            </Container>
        </div>
    )
}
