import React from 'react'
import { Link } from 'react-router-dom'

export default function ErrorPage() {
  return (
    <div>
      <h1 style={{ fontFamily: "Arial", fontWeight: "bold", color: "#f01", textAlign: "center" }}>Sayfa Bulunamadı...</h1>
      <div style={{ textAlign: "center" }}>
        <Link to="/">Anasayfaya Dön</Link>
      </div>
    </div>
  )
}
