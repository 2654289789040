import React, { useMemo, } from 'react'
// /*global google*/
import "../styles/index.css"
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

import Iletisim from '../components/Iletisim/Iletisim';

const containerStyle = {
  width: '100%',
  height: '500px'
};

export default function Contact() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAHuNn1-50do8yx0h-WuzrCOIQol7fK72c",
  });

  const center = useMemo(() => ({ lat: 38.67506, lng: 29.41897 }), [])

  if (!isLoaded) {
    return (
      <div><h1>Haritalar Açılıyor...</h1>
      </div>
    )
  }
  return (
    <div>
      <GoogleMap zoom={20}
        center={center} mapContainerStyle={containerStyle}>
        {/* <>
          <Marker position={center} clickable label={"Professional Team"} />
        </> */}
      </GoogleMap>
      <Iletisim />
    </div>
  )
}
