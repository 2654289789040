import React, { useState, useContext } from 'react'
import "./Navbar.css";
import logo from "../../assets/logo3.jpeg"
import {
    PrimaryNav, Menu, MenuLink, NavbarLinkExtended,
    NavLogo, MenuRight, NavbarExtendedContainer
} from "./Navbar.style"
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { MdCancel } from "react-icons/md";

import { signOut } from "firebase/auth"
import { UserAuth } from "../../firebase/firebase"
import { UserContext } from '../../context/UserContext';

export default function Navbar() {
    const [extendNavbar, setExtendNavbar] = useState(false);
    const { user } = useContext(UserContext)

    const SignOut = async () => {
        await signOut(UserAuth)
            .then(() => {
                console.log("BAŞARILI")
                //console.log("KULLANICI", UserAuth.currentUser)
                //setUser()
                //Navigate({ replace: "/" })
            })
    }

    return (
        <PrimaryNav extendNavbar={extendNavbar}>
            <NavLogo src={logo} alt='logo' />
            <Menu>
                <MenuLink to="/">Anasayfa</MenuLink>
                <MenuLink to="egitim">Eğitimler</MenuLink>
                <MenuLink to="contact" >İletişim</MenuLink>
                <MenuLink to="about">Hakkında</MenuLink>
                <MenuLink to="product">Ürünler</MenuLink>
                <MenuLink to="dergi">One More Dergi</MenuLink>
                {
                    user === undefined ?
                        <MenuLink to="yonetim">Giriş Yap</MenuLink>
                        :
                        <>
                            <MenuLink to="yonetim">Yönet</MenuLink>
                            <MenuLink onClick={() => SignOut()} role="button">Çıkış Yap</MenuLink>
                        </>
                }
            </Menu >

            {extendNavbar && (
                <NavbarExtendedContainer>
                    <NavbarLinkExtended onClick={() => setExtendNavbar((curr) => !curr)} to="/">Anasayfa</NavbarLinkExtended>
                    <NavbarLinkExtended onClick={() => setExtendNavbar((curr) => !curr)} to="egitim" >Eğitimler</NavbarLinkExtended>
                    <NavbarLinkExtended onClick={() => setExtendNavbar((curr) => !curr)} to="contact" >İletişim</NavbarLinkExtended>
                    <NavbarLinkExtended onClick={() => setExtendNavbar((curr) => !curr)} to="about">Hakkında</NavbarLinkExtended>
                    <NavbarLinkExtended onClick={() => setExtendNavbar((curr) => !curr)} to="product">Ürünler</NavbarLinkExtended>
                    <NavbarLinkExtended onClick={() => setExtendNavbar((curr) => !curr)} to="dergi">One More Dergi</NavbarLinkExtended>
                    {
                        user === undefined ?
                            <NavbarLinkExtended onClick={() => setExtendNavbar((curr) => !curr)} to="yonetim">Giriş Yap</NavbarLinkExtended>
                            :
                            <>
                                <NavbarLinkExtended onClick={() => setExtendNavbar((curr) => !curr)} to="yonetim">Yönet</NavbarLinkExtended>
                                <NavbarLinkExtended onClick={() => {
                                    SignOut()
                                    setExtendNavbar((curr) => !curr)
                                }} to="yonetim">Çıkış Yap</NavbarLinkExtended>
                            </>
                    }

                </NavbarExtendedContainer>
            )
            }
            <MenuRight>
                {
                    extendNavbar ?
                        <MdCancel onClick={() => {
                            setExtendNavbar((curr) => !curr);
                        }} size={"100%"} />
                        :
                        <AiOutlineMenuUnfold onClick={() => {
                            setExtendNavbar((curr) => !curr);
                        }} size={"100%"} />
                }
            </MenuRight>
        </PrimaryNav >
    )
}
