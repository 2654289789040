import styled from 'styled-components'

export const IletisimBox = styled.nav`
  height: 600px;
  width:800px;
  background: #fff;
  margin:auto;
  margin-top:-22px;

  @media (max-width: 800px) {
    width: 100vh;
  } 

/*   
  @media (min-width: 800px) {
    height: 90px;
  } */
`;

export const IletisimLink = styled.button`
  color: #fff;
  background-color:#003;
  height:50px;
  width:400px;
  font-size:1.4em;
  align-items: center;
  justify-content: center;
  text-decoration:none;
  }
  &:hover {
    background-color:#066;
    color:white;
  }
  &:active {
    color: red;
  }
`