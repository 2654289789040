import styled from 'styled-components';
import { NavLink as Link } from "react-router-dom";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
  height:200px;
  background-image: linear-gradient(to bottom right, #fffffe, #ffffff);
`

export const MenuLink = styled(Link)`
  color: #fff;
  background-color:#003;
  font-size:1.3em;
  font-weight:bold;
  padding:10px 50px;
  text-decoration:none;
  }
  &:hover {
    background-color:#054;
    color:#fff;
  }
  &:active {
    color: red;
  }
`