import React, { useState, useEffect } from 'react'
import { collection, deleteDoc, addDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import { db, MyStorage } from "../firebase/firebase";
import { ref } from "firebase/storage"
import { AiFillDelete, AiFillEdit } from "react-icons/ai"
import Loading from "./Loading"

export default function Yonetim() {
  const [loading, setLoading] = useState(false)
  const [mesajlar, setMesajlar] = useState([])
  const [about, setAbout] = useState([])
  const [aboutEkle, setAboutEkle] = useState("")
  const [aboutEdit, setAboutEdit] = useState("")
  const [aboutEditActive, setAboutEditActive] = useState(false)
  const [egitim, setEgitim] = useState([])
  const [egitimEditActive, setEgitimEditActive] = useState(false)

  const [egitimEkleBaslikEdit, setEgitimEkleBaslikEdit] = useState("")
  const [egitimEkleDetayEdit, setEgitimEkleDetayEdit] = useState("")
  const [egitimEkleVideoEdit, setEgitimEkleVideoEdit] = useState("")

  const [egitimEkleBaslik, setEgitimEkleBaslik] = useState("")
  const [egitimEkleDetay, setEgitimEkleDetay] = useState("")
  const [egitimEkleVideo, setEgitimEkleVideo] = useState("")


  const [urun, setUrun] = useState([])
  const [urunAd, setUrunAd] = useState("")
  const [urunDetayBaslik, setUrunDetayBaslik] = useState("")
  const [urunDetay1, setUrunDetay1] = useState("")
  const [urunDetay2, setUrunDetay2] = useState("")
  const [urunDetay3, setUrunDetay3] = useState("")
  const [urunVideo, setUrunVideo] = useState("")
  const [urunResim, setUrunResim] = useState("")


  const [etkinlik, setEtkinlik] = useState([])
  const [etkinlikAd, setEtkinlikAd] = useState("")
  const [etkinlikDetay, setEtkinlikDetay] = useState("")
  const [etkinlikVideo, setEtkinlikVideo] = useState("")


  async function writeEtkinlik(etkinlikAd, etkinlikDetay, etkinlikVideo) {
    setLoading(true)
    await addDoc(collection(db, "etkinlik/"), {
      etkinlikAd: etkinlikAd,
      etkinlikDetay: etkinlikDetay,
      etkinlikVideo: etkinlikVideo
    })
      .then(() => {
        setLoading(false)
        setEtkinlikAd("")
        setEtkinlikDetay("")
        setEtkinlikVideo("")
        window.location.reload(false);
      })
      .catch(() => { setLoading(false) })
  }

  const deleteEtkinlik = async (id) => {
    await deleteDoc(doc(db, "etkinlik/" + id))
      .then(() => {
        setLoading(false)
        window.location.reload(false);
      })
  }

  const MyEtkinlik = async () => {
    const querySnapshot = await getDocs(collection(db, "etkinlik"));
    let document = []
    querySnapshot.forEach((doc) => {
      document.push({ ...doc.data(), id: doc.id })
    });
    setEtkinlik(document)
  }



  async function writeUrun(urunAd, urunResim, urunVideo, urunDetayBaslik, urunDetay1, urunDetay2, urunDetay3) {
    setLoading(true)
    await addDoc(collection(db, "urun/"), {
      urunAd: urunAd,
      urunResim: urunResim,
      urunVideo: urunVideo,
      urunDetayBaslik: urunDetayBaslik,
      urunDetay1: urunDetay1,
      urunDetay2: urunDetay2,
      urunDetay3: urunDetay3,
    })
      .then(() => {
        setLoading(false)
        setUrunAd("")
        setUrunResim("")
        setUrunDetayBaslik("")
        setUrunDetay1("")
        setUrunDetay2("")
        setUrunDetay3("")
        setUrunVideo("")
        window.location.reload(false);
      })
      .catch(() => { setLoading(false) })
  }

  const deleteUrun = async (id) => {
    await deleteDoc(doc(db, "urun/" + id))
      .then(() => {
        setLoading(false)
        window.location.reload(false);
      })
  }

  const MyUrun = async () => {
    const querySnapshot = await getDocs(collection(db, "urun"));
    let document = []
    querySnapshot.forEach((doc) => {
      document.push({ ...doc.data(), id: doc.id })
    });
    setUrun(document)
  }


  async function writeEgitim(egitimEkleBaslik, egitimEkleVideo, egitimEkleDetay) {
    setLoading(true)
    await addDoc(collection(db, "egitim/"), {
      egitimBaslik: egitimEkleBaslik,
      egitimVideo: egitimEkleVideo,
      egitimDetay: egitimEkleDetay,
    })
      .then(() => {
        setLoading(false)
        setEgitimEkleBaslik("")
        setEgitimEkleDetay("")
        setEgitimEkleVideo("")
        window.location.reload(false);
      })
      .catch(() => { setLoading(false) })
  }

  const deleteEgitim = async (id) => {
    await deleteDoc(doc(db, "egitim/" + id))
      .then(() => {
        setLoading(false)
        window.location.reload(false);
      })
  }

  const updateEgitim = async (id, egitimEkleBaslikEdit, egitimEkleDetayEdit, egitimEkleVideoEdit) => {
    const docRef = (doc(db, "egitim/" + id));
    await updateDoc(docRef, {
      egitimBaslik: egitimEkleBaslikEdit,
      egitimDetay: egitimEkleDetayEdit,
      egitimVideo: egitimEkleVideoEdit,
    })
      .then(() => {
        setLoading(false)
        setAboutEdit("")
        setAboutEditActive(false)
        window.location.reload(false);
      })
  }

  const MyEgitim = async () => {
    const querySnapshot = await getDocs(collection(db, "egitim"));
    let document = []
    querySnapshot.forEach((doc) => {
      document.push({ ...doc.data(), id: doc.id })
    });
    setEgitim(document)
    console.log(egitim)
  }




  async function writeAbout(item) {
    setLoading(true)
    await addDoc(collection(db, "about/"), {
      item: item,
    })
      .then(() => {
        setLoading(false)
        setAboutEkle("")
        window.location.reload(false);
      })
      .catch(() => { setLoading(false) })
  }


  const updateItem = async (id, data) => {
    const docRef = (doc(db, "about/", id));
    await updateDoc(docRef, { item: data })
      .then(() => {
        setLoading(false)
        setAboutEdit("")
        setAboutEditActive(false)
        window.location.reload(false);
      })
  }

  const deleteAbout = async (id) => {
    await deleteDoc(doc(db, "about/" + id))
      .then(() => {
        setLoading(false)
        window.location.reload(false);
      })
  }

  const MyAbout = async () => {
    const querySnapshot = await getDocs(collection(db, "about"));
    let document = []
    querySnapshot.forEach((doc) => {
      document.push({ ...doc.data(), id: doc.id })
    });
    setAbout(document)
    //console.log(mesajlar)
  }



  const deleteItem = async (id) => {
    await deleteDoc(doc(db, "mesajlar/" + id))
      .then(() => {
        setLoading(false)
        window.location.reload(false);
      })
  }

  const MyMessage = async () => {
    const querySnapshot = await getDocs(collection(db, "mesajlar"));
    let document = []
    querySnapshot.forEach((doc) => {
      document.push({ ...doc.data(), id: doc.id })
    });
    setMesajlar(document)
    //console.log(mesajlar)
  }
  useEffect(() => {
    MyMessage()
    MyAbout()
    MyEgitim()
    MyUrun()
    MyEtkinlik()
  }, [])

  return (
    <div className='container'>
      {
        loading === true ?
          <Loading />
          :
          <>
            <div className='mb-5'>
              <h3>Professional Team</h3>
              <p>Hoşgeldiniz...</p>
            </div>
            <div className="accordion mt-5 mb-5" id="accordion1">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <h2>Gelen Mesajlar <span style={{ color: "#fff", backgroundColor: "#a11111" }} className="badge">{mesajlar.length.toString()}</span></h2>
                  </button>
                </h2>
                {
                  mesajlar.length > 0 ?
                    (
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion1">
                        <div className="accordion-body">
                          <table className="table table-striped mb-5">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">Adı Soyadı</th>
                                <th scope="col">Telefon</th>
                                <th scope="col">Konu</th>
                                <th scope="col">Email</th>
                                <th scope="col">İçerik</th>
                                <th scope="col">Sil</th>
                              </tr>
                            </thead>
                            <tbody>

                              {mesajlar.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.adsoyad}</td>
                                  <td>{item.tel}</td>
                                  <td>{item.konu}</td>
                                  <td>{item.email}</td>
                                  <td>{item.icerik}</td>
                                  <td><AiFillDelete onClick={() => { deleteItem(item.id) }} size={26} color="#D23369" /></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )
                    :
                    <>
                      <h5 className='text-center'>Mesajlar Bulunamadı...</h5>
                      <hr />
                    </>
                }
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="heading2">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                    <h2>Hakkımızda Bölümü</h2>
                  </button>
                </h2>
                <div id="collapse2" className="accordion-collapse collapse show" aria-labelledby="heading2" data-bs-parent="#accordion2">
                  <div className="accordion-body">
                    <div className='row'>
                      <div className="row">
                        <div className="col-12">
                          <h4>Hakkımızda Bölümüne Ekle</h4>
                        </div>
                      </div>
                      <div className='col-7'>
                        <input type="text" value={aboutEkle} onChange={(e) => setAboutEkle(e.target.value)} className='form-control' />
                      </div>
                      <div className='col-5'>
                        <button disabled={aboutEkle == "" ? true : false} className="btn btn-success btn-block" onClick={() => writeAbout(aboutEkle)} type="button">Ekle</button>
                      </div>
                    </div>
                    <hr />


                    <div className='mt-5 mb-5'>
                      <h2>Hakkımızda Sayfası Maddeleri</h2>
                      {
                        about.length > 0 ?
                          (
                            <table className="table table-striped mb-5">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">Hakkımızda Maddeleri</th>
                                  <th scope="col">Düzenle</th>
                                  <th scope="col">Sil</th>
                                </tr>
                              </thead>
                              <tbody>
                                {about.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.item}</td>
                                    <td><AiFillEdit onClick={() => { setAboutEditActive(true) }} size={26} color="#a23369" /></td>
                                    {
                                      aboutEditActive === true ?
                                        <table>
                                          <tr>
                                            <td>Düzenle</td>
                                          </tr>
                                          <tr>
                                            <td><input type="text" value={aboutEdit} onChange={(e) => setAboutEdit(e.target.value)} className='form-control' /></td>
                                          </tr>
                                          <tr>
                                            <td><button disabled={aboutEdit == "" ? true : false} className="btn btn-success btn-block" onClick={() => updateItem(item.id, aboutEdit)} type="button">Güncelle</button></td>
                                          </tr>
                                        </table>
                                        : null}
                                    <td><AiFillDelete onClick={() => { deleteAbout(item.id) }} size={26} color="#D23369" /></td>
                                  </tr>
                                ))}
                              </tbody>

                            </table>
                          )
                          :
                          <h5 className='text-center'>İçerik Bulunamadı...</h5>
                      }
                    </div>
                  </div>
                </div>
              </div>


              <div className="accordion-item">
                <h2 className="accordion-header" id="heading3">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                    <h2>Eğitim</h2>
                  </button>
                </h2>
                <div id="collapse3" className="accordion-collapse collapse show" aria-labelledby="heading3" data-bs-parent="#accordion1">
                  <div className="accordion-body">
                    <div className='mt-1 mb-5'>
                      <h4>Eğitim Ekle</h4>
                      <form>
                        <div className='form-group'>
                          <input type="text" value={egitimEkleBaslik} placeholder="Eğitim Başlığı"
                            onChange={(e) => setEgitimEkleBaslik(e.target.value)} className='form-control' />
                          <br />
                          <input type="text" value={egitimEkleVideo} placeholder="Eğitim Video Linki"
                            onChange={(e) => setEgitimEkleVideo(e.target.value)} className='form-control' />
                          <br />
                          <textarea className="form-control" placeholder='Eğitim Açıklaması'
                            value={egitimEkleDetay} onChange={(e) => setEgitimEkleDetay(e.target.value)} rows="3"></textarea>
                          <br />
                          <button className="btn btn-success btn-block" onClick={() => writeEgitim(egitimEkleBaslik, egitimEkleVideo, egitimEkleDetay)}
                            disabled={egitimEkleBaslik == "" || egitimEkleVideo == "" || egitimEkleDetay == "" ? true : false} type="button">Ekle</button>
                          <br /><hr />
                        </div>
                      </form>
                    </div>


                    <div className='mt-5 mb-5'>
                      <h2>Eğitim Sayfası</h2>
                      {
                        egitim.length > 0 ?
                          (
                            <table className="table table-striped mb-5">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">Başlık</th>
                                  <th scope="col">Detay</th>
                                  <th scope="col">Video ID</th>
                                  <th scope="col">Düzenle</th>
                                  <th scope="col">Sil</th>
                                </tr>
                              </thead>
                              <tbody>
                                {egitim.map((item) => (
                                  <>
                                    <tr key={item.id}>
                                      <td>{item.egitimBaslik}</td>
                                      <td>{item.egitimDetay}</td>
                                      <td>{item.egitimVideo}</td>
                                      <td><AiFillEdit onClick={() => { setEgitimEditActive(true) }} size={26} color="#a23369" /></td>
                                      <td><AiFillDelete onClick={() => { deleteEgitim(item.id) }} size={26} color="#D23369" /></td>
                                    </tr>
                                    {
                                      egitimEditActive === true ?
                                        <>
                                          <tr>
                                            <td><b>Düzenle</b></td>
                                          </tr>
                                          <tr>
                                            <td>Başlık</td>
                                            <td>Detay</td>
                                            <td>Video ID</td>
                                          </tr>
                                          <tr>
                                            <td><input type="text" value={egitimEkleBaslikEdit} onChange={(e) => setEgitimEkleBaslikEdit(e.target.value)} className='form-control' /></td>
                                            <td><input type="text" value={egitimEkleDetayEdit} onChange={(e) => setEgitimEkleDetayEdit(e.target.value)} className='form-control' /></td>
                                            <td><input type="text" value={egitimEkleVideoEdit} onChange={(e) => setEgitimEkleVideoEdit(e.target.value)} className='form-control' /></td>
                                          </tr>
                                          <tr>
                                            <td><button className="btn btn-success btn-block" onClick={() => updateEgitim(item.id, egitimEkleBaslikEdit, egitimEkleDetayEdit, egitimEkleVideoEdit)} type="button">Güncelle</button></td>
                                          </tr>
                                        </>
                                        : null}
                                  </>
                                ))}
                              </tbody>
                            </table>
                          )
                          :
                          <h5 className='text-center'>İçerik Bulunamadı...</h5>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="heading4">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                    <h2>Ürünler Sayfası</h2>
                  </button>
                </h2>
                <div id="collapse4" className="accordion-collapse collapse show" aria-labelledby="heading4" data-bs-parent="#accordion1">
                  <div className="accordion-body">
                    <div className='mt-1 mb-5'>
                      <h4>Ürün Ekle</h4>
                      <form>
                        <div className='form-group'>
                          <input type="text" value={urunAd} placeholder="Ürün Adı"
                            onChange={(e) => setUrunAd(e.target.value)} className='form-control' />
                          <br />
                          <input type="text" value={urunResim} placeholder="Ürün Resim Linki"
                            onChange={(e) => setUrunResim(e.target.value)} className='form-control' />
                          <br />
                          <br />
                          <p>Video linki olarak Kırmızı Yazılı kısım buraya girelecektir. Aşağıda örnekler verilmiştir...</p>
                          <p>https://www.youtube.com/watch?v=<b className="text-danger">yUYfS8wXsBU</b></p>
                          <p>https://www.youtube.com/watch?v=<b className="text-danger">7wLN-u6hf8Q</b>&t=2938s</p>
                          <input type="text" value={urunVideo} placeholder="Ürün Video Linki"
                            onChange={(e) => setUrunVideo(e.target.value)} className='form-control' />
                          <br />
                          <input type="text" value={urunDetayBaslik} placeholder="Açıklama Başlık"
                            onChange={(e) => setUrunDetayBaslik(e.target.value)} className='form-control' />
                          <br />
                          <textarea className="form-control" placeholder='Ürün Açıklama 1'
                            value={urunDetay1} onChange={(e) => setUrunDetay1(e.target.value)} rows="3"></textarea>
                          <br />
                          <textarea className="form-control" placeholder='Ürün Açıklama 2'
                            value={urunDetay2} onChange={(e) => setUrunDetay2(e.target.value)} rows="3"></textarea>
                          <br />
                          <textarea className="form-control" placeholder='Ürün Açıklama 3'
                            value={urunDetay3} onChange={(e) => setUrunDetay3(e.target.value)} rows="3"></textarea>
                          <br />
                          <button className="btn btn-success btn-block" onClick={() => writeUrun(urunAd, urunResim, urunVideo, urunDetayBaslik, urunDetay1, urunDetay2, urunDetay3)}
                            disabled={urunAd == "" || urunResim == "" || urunDetayBaslik == "" || urunDetay1 == "" ? true : false} type="button">Ekle</button>
                          <br /><hr />
                        </div>
                      </form>
                    </div>


                    <div className='mt-5 mb-5'>
                      <h2>Ürünler</h2>
                      {
                        urun.length > 0 ?
                          (
                            <table className="table table-striped mb-5">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">Adı</th>
                                  <th scope="col">Detay Başlık</th>
                                  <th scope="col">Detay-1</th>
                                  <th scope="col">Detay-2</th>
                                  <th scope="col">Detay-3</th>
                                  <th scope="col">Sil</th>
                                </tr>
                              </thead>
                              <tbody>
                                {urun.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.urunAd}</td>
                                    <td>{item.urunDetayBaslik}</td>
                                    <td>{item.urunDetay1}</td>
                                    <td>{item.urunDetay2}</td>
                                    <td>{item.urunDetay3}</td>
                                    <td><AiFillDelete onClick={() => { deleteUrun(item.id) }} size={26} color="#D23369" /></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )
                          :
                          <h5 className='text-center'>Ürün Bulunamadı...</h5>
                      }
                    </div>

                  </div>
                </div>
              </div>


              <div className="accordion-item">
                <h2 className="accordion-header" id="heading5">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                    <h2>Etkinlikler</h2>
                  </button>
                </h2>
                <div id="collapse5" className="accordion-collapse collapse show" aria-labelledby="heading5" data-bs-parent="#accordion1">
                  <div className="accordion-body">
                    <div className='mt-1 mb-5'>
                      <h4>Etkinlik Ekle</h4>
                      <form>
                        <div className='form-group'>
                          <input type="text" value={etkinlikAd} placeholder="Etkinlik Adı"
                            onChange={(e) => setEtkinlikAd(e.target.value)} className='form-control' />
                          <br />
                          {/* <input type="file" accept='image/*' value={etkinlikResim} placeholder="Etkinlik Resmi"
                            onChange={(e) => setEtkinlikResim(e.target.files[0])} className='form-control' />
                          <br /> */}
                          <input type="text" value={etkinlikVideo} placeholder="Etkinlik Video Linki"
                            onChange={(e) => setEtkinlikVideo(e.target.value)} className='form-control' />
                          <br />
                          <textarea className="form-control" placeholder='Etkinlik Açıklaması'
                            value={etkinlikDetay} onChange={(e) => setEtkinlikDetay(e.target.value)} rows="4"></textarea>
                          <br />
                          <button className="btn btn-success btn-block" onClick={() => writeEtkinlik(etkinlikAd, etkinlikDetay, etkinlikVideo)}
                            disabled={etkinlikAd == "" || etkinlikDetay == "" ? true : false} type="button">Ekle</button>
                          <br /><hr />
                        </div>
                      </form>
                    </div>


                    <div className='mt-5 mb-5'>
                      <h2>Etkinlikler</h2>
                      {
                        etkinlik.length > 0 ?
                          (
                            <table className="table table-striped mb-5">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">Adı</th>
                                  <th scope="col">Detay</th>
                                  <th scope="col">Sil</th>
                                </tr>
                              </thead>
                              <tbody>
                                {etkinlik.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.etkinlikAd}</td>
                                    <td>{item.etkinlikDetay}</td>
                                    <td><AiFillDelete onClick={() => { deleteEtkinlik(item.id) }} size={26} color="#D23369" /></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )
                          :
                          <h5 className='text-center'>Etkinlik Bulunamadı...</h5>
                      }
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </>
      }
    </div>
  )
}
