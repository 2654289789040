import React from 'react'
import { Container, } from "./EgitimCard.style"
import { Fade, Slide, } from "react-awesome-reveal";
import YouTube from 'react-youtube';

export default function EgitimCard(props) {
  let egitim = props.video
  const opts = {
    height: "400px",
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.startVideo();
  }

  console.log("Urun videoID", props)
  return (
    // <Container>
    <div className='container pt-5 pb-1'>
      <div className='row'>
        <div className='col-5'>
          <Slide><h3 className='text-center' style={{ color: "#003" }}>{props.baslik}</h3></Slide>
          <Slide><p style={{ color: "#001" }}>{props.detay}</p></Slide>
        </div>
        <div className='col-7'>
          {/* <iframe width="100%" height="400"
            src={"https://www.youtube.com/embed/"+props.video+"?autoplay=0&mute=1"}>
          </iframe> */}
          <YouTube videoId={egitim} opts={opts} onReady={_onReady} />
        </div>
      </div>
    </div>
    // </Container>
  )
}
