import React, { useEffect, useState } from 'react'
import Bilet from '../components/Bilet/Bilet'
import LiderCard from '../components/LiderCard/LiderCard'
import EgitimHomeCard from '../components/EgitimCard/EgitimHomeCard/EgitimHomeCard'
import EtkinlikCard from '../components/Etkinlik/EtkinlikCard'
import { db } from '../firebase/firebase'
import { collection, deleteDoc, addDoc, doc, getDocs } from "firebase/firestore";
import { Link } from 'react-router-dom'

export default function Home() {
  const [etkinlik, setEtkinlik] = useState([])
  const [urun, setUrun] = useState([])

  const MyEtkinlik = async () => {
    const querySnapshot = await getDocs(collection(db, "etkinlik"));
    let document = []
    querySnapshot.forEach((doc) => {
      document.push({ ...doc.data(), id: doc.id })
    });
    setEtkinlik(document)
  }
  const MyUrun = async () => {
    const querySnapshot = await getDocs(collection(db, "urun"));
    let document = []
    querySnapshot.forEach((doc) => {
      document.push({ ...doc.data(), id: doc.id })
    });
    setUrun(document)
  }
  useEffect(() => {
    MyEtkinlik()
    MyUrun()
  }, [])
  return (
    <>
      <Bilet />
      <div className='container pt-5'>
        <div className='row'>
          <div className='col-12'>
            <h2>One More International</h2>
            <br />
            <p>
              2014 yılında ticari hayatına başlayan One More International, “Temiz Network Marketing Sahası Oluşturma” misyonu ile faaliyetlerine başlamış olup, bu misyonu kendine daimi hedef olarak belirlemiştir. Nitelikli ürünleri ile girişimcilerine, küresel pazarda kazanç fırsatı sunmaktadır. Bu organizasyonun temelleri atıldıktan sonra yıllar içinde Türkiye’nin dört bir yanına, sonrasında Avrupa’ya ve Avrupa’dan tüm global pazara açılmaya başlayan One More International, Dünya’nın 6 farklı noktasında kurumsal yapılarını kurarak küresel ticaretin iddialı oyuncularından biri olmuştur.
            </p>
            <p>
              One More International, benimsemiş olduğu “küresel serbest ticaret” yapısını Türkiye, Hollanda, Bulgaristan, Azerbaycan, Kazakistan ve Endonezya’da kurulmuş olan şirketleri ile temsilcilerine temin etmektedir.
            </p>
            <br />
            <p>
              Bugün One More International; 160 ülkeye açılmış, kişiye özel kargosuyla tüm dünyaya servis yapabilen, yaklaşık 7 ülkeye makro ihracat gerçekleştirmiş, bununla beraber 16 dünya ülkesinde ofisleri bulunan, Türkiye’de de 3 tane ofisi olan bir “Network Marketing” şirketidir.
            </p>
          </div>
        </div>
      </div>
      <LiderCard />

      <div className='container'>
        <div className='row justify-content-center mt-5 m-2'>
          {
            urun.map((item) => (
              <div className='col-3 mb-1'>
                <Link to={"/productdetail#title"} state={[item.urunAd, item.urunResim, item.urunDetayBaslik,
                item.urunDetay1, item.urunDetay2, item.urunDetay3, item.urunVideo]}>
                  <img src={item.urunResim} className="img-fluid" width="200" height="300" maxwidth="200" maxheight="300" />
                </Link>
              </div>
            ))
          }
        </div>
      </div>
      <EgitimHomeCard />
      {
        etkinlik.map((item) => (
          <>
            <EtkinlikCard etkinlikAd={item.etkinlikAd} etkinlikDetay={item.etkinlikDetay}
              etkinlikVideo={item.etkinlikVideo} />
          </>
        ))
      }
    </>
  )
}
