import React from 'react'
import { Container, } from "./EtkinlikCard.style"
import { Slide,  } from "react-awesome-reveal";
import YouTube from 'react-youtube';

export default function EtkinlikCard(props) {
  const opts = {
    height: "400px",
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.startVideo();
  }
  return (
    <Container>
        <div className='container mt-5 mb-5'>
          <div className='row'>
            <div className='col-6'>
            <h3 className='text-center' style={{ color: "#003" }}>{props.etkinlikAd}</h3>
              <p style={{ color: "#044" }}>{props.etkinlikDetay}</p>
            </div>
            <div className='col-6'>
              <YouTube videoId={props.etkinlikVideo} opts={opts} onReady={_onReady} />
            </div>
          </div>
        </div>
    </Container>
  )
}
