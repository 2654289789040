import React from 'react'
import { Container, MenuLink } from "./EgitimHomeCard.style"
import { Fade, Slide, Bounce } from "react-awesome-reveal";
// import { Col, Row } from 'react-bootstrap';

export default function EgitimHomeCard() {
    return (
        <Container>
            <Fade>
                <div className='container pl-3'>
                    <div className='row'>
                        <div className='col'>
                            <h1>Eğitimlerimiz</h1>
                            <h4>Professional Team olarak düzenlediğimiz kamplar ve etkinliklerden harika eğitimlere ulaşın....</h4>
                            <br />
                            <Bounce>
                                <MenuLink to="egitim">İncele</MenuLink>
                            </Bounce>
                        </div>
                    </div>
                </div>
            </Fade >
        </Container>
    )
}
