import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

import { UserProvider } from './context/UserContext';

import App from './pages/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UserProvider>
      <App />
    </UserProvider>
);