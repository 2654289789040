import React, { useContext } from 'react';
import { LoginBox, LoginLink } from './Login.style';
import { Formik } from 'formik';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth"
import { UserAuth } from "../../firebase/firebase"
import { UserContext } from '../../context/UserContext';


export default function Login() {
    const { setUser } = useContext(UserContext)

    const SignIn = async (email, password) => {
        await signInWithEmailAndPassword(UserAuth, email, password)
            .then(() => {
                console.log("BAŞARILI")
                setUser(UserAuth.currentUser)
                //window.location.reload(false);
            })
    }

    return (
        <LoginBox style={{
            boxShadow: '0px 0px 13px 2px #aaa',
            marginTop: '0em',
            paddingLeft: '4em',
            paddingTop: '2em',
            marginBottom: "6em"
        }}>
            <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={(values) => {
                    SignIn(values.email, values.password)
                }}
            >
                {({ handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <div className="col-sm-10">
                                <label htmlFor="email">Email Adresi</label>
                                <input type="email" onChange={handleChange} name="email" className="form-control"
                                    placeholder="E-mail adresi giriniz..." />
                            </div>
                        </div>
                        <br />
                        <div>
                            <label htmlFor="password">Şifre</label>
                            <div className="col-sm-10">
                                <input type="password" onChange={handleChange} name="password" className="form-control"
                                    placeholder="Paralonızı giriniz..." />
                            </div>
                        </div>
                        <br />
                        <LoginLink type="submit">Giriş Yap</LoginLink>
                        <br />
                        {/* <LoginLink to="/" type="submit">Kaydol</LoginLink> */}
                    </form>
                )}
            </Formik>
        </LoginBox>
    );
}
