import React, { useContext } from "react"
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom'

import { UserContext } from '../context/UserContext'

import Home from './Home'
import Layout from "./Layout";
import ErrorPage from "./ErrorPage";
import Contact from "./Contact";
import About from "./About";
import Yonetim from "./Yonetim";
import LoginPage from "./LoginPage";
import Egitim from "./Egitim";
import Loading from "./Loading";
import DergiPage from "./DergiPage";
import ProductPage from "./ProductPage";
import ProductDetail from "./ProductDetail";

export default function App() {
    const { user, initialize } = useContext(UserContext)
    return (
        <Router>
            <Routes>
                <Route element={<Layout />}>
                    {
                        initialize === false ?
                            <>
                                <Route element={<Home />} path="/" exact />
                                <Route element={<About />} path="about" />
                                <Route element={<Contact />} path="contact" />
                                <Route element={<ProductPage />} path="product" />
                                <Route element={<ProductDetail />} path="productdetail" />
                                <Route element={<Egitim />} path="egitim" />
                                <Route element={<DergiPage />} path="dergi" />
                                {
                                    user !== undefined ?
                                        <Route element={<Yonetim />} path="yonetim" />
                                        :
                                        <Route element={<LoginPage />} path="yonetim" />
                                }
                                <Route element={<ErrorPage />} path="*" />
                            </>
                            :
                            <Route element={<Loading />} path="*" />
                    }
                </Route>
            </Routes>
        </Router>
    )
}