import React, { useState, useEffect } from 'react'
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import YouTube from 'react-youtube';
import { FacebookShareButton, FacebookIcon } from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Printer, { print } from 'react-pdf-print'
import { AiFillPrinter, AiFillCopy } from "react-icons/ai"
import { Link } from 'react-router-dom';

export default function ProductPage() {
    const opts = {
        height: "500px",
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };
    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.startVideo();
    }
    const ids = ['0', "1", "2"]

    const [copyVal, setCopyVal] = useState("")
    const [copied, setCopied] = useState(false)
    const [urun, setUrun] = useState([])
    const MyUrun = async () => {
        const querySnapshot = await getDocs(collection(db, "urun"));
        let document = []
        querySnapshot.forEach((doc) => {
            document.push({ ...doc.data(), id: doc.id })
        });
        setUrun(document)
    }
    useEffect(() => {
        MyUrun()
    }, [])
    return (
        <div class="container">
            {
                urun.length > 0 ?
                    <div class="row justify-content-around mb-5">
                        {
                            urun.map((item, index) => (
                                <>
                                    <div class="col-3 mb-5 m-1" key={index}>
                                        <Link to={"/productdetail"} state={[item.urunAd, item.urunResim, item.urunDetayBaslik,
                                        item.urunDetay1, item.urunDetay2, item.urunDetay3,item.urunVideo]}>
                                            <img  className="img-fluid" src={item.urunResim} alt="logo" width="100%" height="100%" />
                                            <h4 style={{ textDecoration: "none" }} className='text-center'>{item.urunAd}</h4>
                                        </Link>
                                        {/* <Printer>
                                            <div id="myModal" class="modal fade modal-xl" role="dialog">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                                            <h4 id={ids[0]} class="modal-title">{item.urunAd}</h4>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    <img src={item.urunResim} alt="logo"
                                                                        minHeight="294" minWidth="424" width="100%" height="100%" />
                                                                </div>
                                                                <div className='col-7'>
                                                                    <p id={ids[1]}>{item.urunDetay1}</p><br />
                                                                    <div className="row">
                                                                        <b><i>Paylaş</i></b>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm">
                                                                            <br />
                                                                            <button onClick={() => print(ids)}>
                                                                                <AiFillPrinter size={23} /></button>
                                                                            <CopyToClipboard text={[item.urunAd, "\n\n", item.urunDetay1, "\n\n", item.urunDetay2, "\n\n", item.urunDetay3]}
                                                                                onCopy={() => setCopied(true)}>
                                                                                <AiFillCopy size={23} />
                                                                            </CopyToClipboard>
                                                                            <FacebookShareButton
                                                                                url={'https://www.professionalteam.com.tr/product'}
                                                                                quote={[item.urunAd, item.urunResim, item.urunDetay1, item.urunDetay2, item.urunDetay3]}
                                                                                title={item.urunAd}
                                                                                text={item.urunAd}
                                                                                hashtag="#professionalteam"
                                                                            >
                                                                                <FacebookIcon size={23} round />
                                                                            </FacebookShareButton>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {copied ? <span style={{ color: 'red' }}>Kopyalandı</span> : null}
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                    <h5>{item.urunDetayBaslik}</h5><br />
                                                                    {
                                                                        item.urunDetay2 != "" &&
                                                                        <p>{item.urunDetay2}<br /></p>
                                                                    }
                                                                    <br /><br />
                                                                    {
                                                                        item.urunDetay3 != "" &&
                                                                        <p>{item.urunDetay3}<br /></p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <b><p>Detaylı bilgi için videomuzu izleyebilirsiniz...</p></b>
                                                                    <br />
                                                                    {
                                                                        item.urunVideo != "" &&
                                                                        <YouTube videoId={item.urunVideo} opts={opts} onReady={_onReady} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Printer> */}
                                    </div>

                                </>
                            ))}
                    </div>
                    :
                    <h1 className='text-center mb-5 mt-5'>Ürünler Bulunamadı...</h1>
            }
        </div>
    )
}