import React from 'react'

export default function DergiPage() {
    return (
        <div class="container mb-5 pb-5">
            <div class="row">
                <div class="col-sm">
                    <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/14.pdf' target={'_blank'} rel="noreferrer">
                        <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-14.png" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
                <div class="col-sm">
                    <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/13.pdf' target={'_blank'} rel="noreferrer">
                        <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-13.png" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
                <div class="col-sm">
                    <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/12.pdf' target={'_blank'} rel="noreferrer">
                        <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-12.png" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
                <div class="col-sm">
                    <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/11.pdf' target={'_blank'} rel="noreferrer">
                        <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-11-sonbahar-2020-1-300x424-1.jpeg" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-sm">
                    <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/10.pdf' target={'_blank'} rel="noreferrer">
                        <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-10-kis-2020-300x424-1.jpeg" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
                <div class="col-sm">
                    <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/9.pdf' target={'_blank'} rel="noreferrer">
                        <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-09-yaz-2019-300x424-1.jpeg" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
                <div class="col-sm">
                    <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/8.pdf' target={'_blank'} rel="noreferrer">
                        <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-08-kis-2019-1-300x424-1.jpeg" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
                <div class="col-sm">
                    <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/7.pdf' target={'_blank'} rel="noreferrer">
                        <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-07-bahar-2018-02-300x424-1.jpeg" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-sm">
                <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/6.pdf' target={'_blank'} rel="noreferrer">
                    <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-06-sonbahar-2017-300x424-1.png" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
                <div class="col-sm">
                <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/5.pdf' target={'_blank'} rel="noreferrer">
                    <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-05-bahar-2017-1-300x424-1.jpeg" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
                <div class="col-sm">
                <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/4.pdf' target={'_blank'} rel="noreferrer">
                    <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-04-kis-2017-300x424-1.png" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
                <div class="col-sm">
                <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/3.pdf' target={'_blank'} rel="noreferrer">
                    <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-03-sonbahar-2016-300x424-1.png" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-sm">

                </div>
                <div class="col-sm">
                <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/2.pdf' target={'_blank'} rel="noreferrer">
                    <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-02-bahar-2016-1-300x424-1.jpeg" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
                    </a>
                </div>
                <div class="col-sm">
                <a href='https://dolphinsteam.biz/wp-content/uploads/2021/12/1.pdf' target={'_blank'} rel="noreferrer">
                    <img src="https://dolphinsteam.biz/wp-content/uploads/2021/12/onemore-dergi-01-kis-2016-300x424-1.png" alt="logo" minHeight="294" minWidth="424" width="100%" height="100%" />
               </a>
                </div>
                <div class="col-sm">

                </div>
            </div>
        </div>
    )
}
