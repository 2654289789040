import React, { useEffect, useState } from 'react'
import EgitimCard from '../components/EgitimCard/EgitimCard'
import { collection, deleteDoc, addDoc, doc, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import f2 from "../assets/2.jpeg"
export default function Egitim() {
    const [about, setAbout] = useState([])

    const MyAbout = async () => {
        const querySnapshot = await getDocs(collection(db, "egitim"));
        let document = []
        querySnapshot.forEach((doc) => {
            document.push({ ...doc.data(), id: doc.id })
        });
        setAbout(document)
    }

    useEffect(() => {
        MyAbout()
    }, [])
    return (
        <div>
            <div>
                <div>
                    <img src={f2} className="img-fluid" alt="logo" minWidth="100%" width="100%" height="400" />
                </div>
                {/* <h1 className='text-center' style={{ fontFamily: "Arial", fontWeight: "bold", fontStyle: "italic", color: "#033" }}>EĞİTİMLERİMİZ</h1> */}
                {
                    about.length > 0 ?
                        about.map((item) => (
                            <EgitimCard baslik={item.egitimBaslik} detay={item.egitimDetay} video={item.egitimVideo} />
                        ))
                        :
                        <h3>Mevcut Eğitim Bulunamadı...</h3>
                }
            </div>
        </div>
    )
}
