import { createContext, useState,useEffect } from "react"

import { onAuthStateChanged } from "firebase/auth"
import { UserAuth } from "../firebase/firebase"



export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [initialize, setInitialize] = useState(true);

    useEffect(() => {
        onAuthStateChanged(UserAuth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser)
                setInitialize(false)
                //console.log("USERCONTEXT: ",UserAuth.currentUser)
            }
            else {
                setInitialize(false)
                console.log("KULLANICI BOŞ: ",currentUser)
                setUser()
            }
        })
    }, [user])

    const values = { user, setUser, initialize, setInitialize }
    return (
        <UserContext.Provider value={values}>
            {children}
        </UserContext.Provider>
    )
}
