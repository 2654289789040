import React from 'react'
import {
    Box,
    Container,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./Footer.style"
import { FaInstagram, FaYoutube, FaFacebookSquare, } from "react-icons/fa";

export default function Footer() {
    const year = new Date().getFullYear();
    const Callto = ({ phone, children }) => {
        return <a style={{ textDecoration: "none", color: "#fa1", fontFamily: "Arial", }} href={`tel:${phone}`}>{children}</a>;
    };
    return (
        <Box>
            <h6 style={{
                color: "green",
                textAlign: "center",
                marginTop: "-50px"
            }}>
                ©Professional Team {year} Tüm Hakları Gizlidir.
            </h6>
            <Container>
                <Row>
                    <Column>
                        <Heading>Telefon</Heading>
                        <FooterLink> <Callto phone="+905072751000">+90 507 275 1000</Callto></FooterLink>
                    </Column>
                    <Column>
                        <Heading>Adres</Heading>
                        <FooterLink>
                            Sarayaltı Mah. Celal Bayar Cad. A1 Blok No:88 İçkapı No:101 Kentsel dönüşüm Merkez/UŞAK
                        </FooterLink>
                    </Column>
                    <Column>
                        {/* <Heading>Linkler</Heading>
                        <FooterLink href='/'>Anasayfa</FooterLink>
                        <FooterLink href='contact'>İletişim</FooterLink>
                        <FooterLink href='about'>Hakkında</FooterLink>
                        <FooterLink href='egitim'>Eğitimler</FooterLink>
                        <FooterLink href='product'>Ürünler</FooterLink> */}
                    </Column>
                    <Column>
                        <Heading>Bizi Takip Edin</Heading>
                        <FooterLink target={'_blank'} rel="noreferrer" href="https://www.facebook.com/professionalteamresmi">
                            <i className="fab fa-facebook-f">
                                <span style={{ marginLeft: "10px" }}>
                                    <FaFacebookSquare color="#04f" /> Facebook
                                </span>
                            </i>
                        </FooterLink>
                        <FooterLink target={'_blank'} rel="noreferrer" href="https://www.instagram.com/professionalteamresmi/">
                            <i className="fab fa-instagram">
                                <span style={{ marginLeft: "10px" }}>
                                    <FaInstagram color="#f88" /> Instagram
                                </span>
                            </i>
                        </FooterLink>
                        <FooterLink target={'_blank'} rel="noreferrer" href="https://www.youtube.com/@professional_team">
                            <i className="fab fa-youtube">
                                <span style={{ marginLeft: "10px" }}>
                                    <FaYoutube color="#f33" /> Youtube
                                </span>
                            </i>
                        </FooterLink>
                    </Column>
                </Row>
            </Container>
        </Box>
    )
}
