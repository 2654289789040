import { Outlet } from 'react-router-dom';
import logo from '../assets/logo3.jpeg';
import Navbar from '../components/Navbar/Navbar';
import "../styles/App.css"
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Footer from '../components/Footer/Footer';

import { FaInstagram, FaYoutube, FaFacebookSquare } from "react-icons/fa";

function Layout() {
  return (
    <div>
      <Navbar />
      <div className='icons'>
        <ul>
          <a target={'_blank'} rel="noreferrer" href="https://www.youtube.com/@professional_team"><li className='youtube'><FaYoutube /></li></a>
          <a target={'_blank'} rel="noreferrer" href="https://www.facebook.com/professionalteamresmi"><li className='facebook'><FaFacebookSquare /></li></a>
          <a target={'_blank'} rel="noreferrer" href="https://www.instagram.com/professionalteamresmi/"><li className='instagram'><FaInstagram /></li></a>
        </ul>
      </div>
      <div>
        <Outlet />
      </div>
      <div>
        <Footer />
        <FloatingWhatsApp avatar={logo} accountName='Professional Team' statusMessage='1 saat içinde geri dönelim'
          chatMessage='Merhaba Nasıl Yardımcı Olabilirim' phoneNumber="+905072751000" />
      </div>
    </div >
  );
}

export default Layout;
